<template>
  <svg 
    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    @click="close"
  >
    <path d="M8 8L16 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 8L8 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    methods: {
      close(event){
        this.$emit('close', event)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>