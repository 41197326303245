<template>
  <div>
    <p 
      class="flex items-center justify-between px-4 py-2 border-b border-grey-light-500 cursor-pointer"
      @click="isOpenSupFormats = !isOpenSupFormats"
    >
      <span class="font-grey-darker-500 text-base">Supported formats</span>
      <button class="font-grey-darker-500 text-base">
        <minusInCircle v-if="isOpenSupFormats"/>
        <plusInCircle v-else/>
      </button>
    </p>
    
    <collapse-transition>
      <div v-show="isOpenSupFormats" class="p-4">
        <p class="base-font-gray-light-800 mb-4">
          You can currently add the following formats in the input form.
        </p>
        <p class="base-font-gray-light-800 mb-4">
          https://www.youtube.com/channel/UC[...] UC9XXXXXXXXXXXXXXXXXXXXX
        </p>
      </div>
    </collapse-transition>

    <p 
      class="flex items-center justify-between px-4 py-2 border-b border-grey-light-500 cursor-pointer"
      @click="isOpenFindID = !isOpenFindID"
    >
      <span class="font-grey-darker-500 text-base">How do I find the Channel ID?</span>
      <button class="font-grey-darker-500 text-base">
        <minusInCircle v-if="isOpenFindID"/>
        <plusInCircle v-else/>
      </button>
    </p>
    
    <collapse-transition>
      <div v-show="isOpenFindID" class="p-4">
        <p class="base-font-gray-light-800 mb-4">
          Text
        </p>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
  import { CollapseTransition } from "@ivanv/vue-collapse-transition"
  import minusInCircle from '@/components/icons/minus-in-circle'
  import plusInCircle from '@/components/icons/plus-in-circle'

  export default {
    components: {
      CollapseTransition,
      minusInCircle,
      plusInCircle,
    },
    data(){
      return{
        isOpenSupFormats: false,
        isOpenFindID: false
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>