<template>
  <div class="relative">
    <closeIcon class="cursor-pointer absolute top-0 -right-4" @close="close"/>

    <p class="text-2xl text-black font-bold mb-16 pt-3">Add {{ type }} Manually</p>
    <p class="text-base text-black mb-6">Fill in a {{ typeLower }} ID or full URL</p>
    <form 
      @submit.prevent="matchYoutubeUrl"
      class="flex flex-wrap items-center mb-7"
    >
      <input 
        v-model="youTubeUrl"
        type="text"
        placeholder="Add YouTube URL or ID"
        class="w-full mb-3.5 base-bg-darker-300 youTubeUrl-input rounded px-4 h-8 font-mono text-sm text-black outline-none placeholder-black"
      >

      <ButtonWithLoader 
        :disabled="loading"
        :btnText="`Add ${ type }`"
        type="submit"
        bgClass="purple-bg"
        textClass="purple-light font-monoDemi text-sm"
        heigthClass="h-8"
      />
    </form>

    <faq />
  </div>
</template>

<script>
  import closeIcon from '@/components/icons/close-icon'
  import faq from '@/components/Bucket/faq'
  import ButtonWithLoader from '@/components/ButtonWithLoader'
  import { validateChannelURL, validateChannelID, validateVideoURL, validateID } from '@/helpers/validaions'
  import { formatChannelURLToID } from '@/helpers/formats'
  import { warningToastConfig } from '@/helpers/constants'
  import ToastMessages from '@/data/toast_messages.json'
  import { mapGetters } from 'vuex'
  // import gql from 'graphql-tag'

  export default {
    components: {
      closeIcon,
      faq,
      ButtonWithLoader
    },
    props: [ 'type', 'typeLower' ],
    data(){
      return{
        youTubeUrl: '',
        isOpenSupFormats: false,
        isOpenFindID: false,
        loading: false
      }
    },
    computed: {
      ...mapGetters({
        bucket: 'bucket/bucket',
      })
    },
    methods: {
      addChannel(bucketId, channelId) {
        this.loading = true
        // await this.$mainClient.mutate({
        //   mutation: gql`mutation MyMutation {
        //     createChannelBucketModel(input: {
        //     bucketId: "${bucketId}", 
        //     channelId: "${channelId}", 
        //     channelName: "jkjk"
        //   }) {
        //       channelName
        //       dateAdded
        //     }
        //   }`,
        // })
        // .then((res) => console.log(res))
        return this.$auth.getTokenSilently()
        .then(async (res) => {
          await this.$axios.post(
            `https://xy0qty1qh8.execute-api.us-west-1.amazonaws.com/api/v1/add-channel-bucket`,
            {
              channelId: channelId,
              bucketId: bucketId,
              fullScrape: true
            },
            {
              headers: {
                Authorization: `Bearer ${res}`,
              },
            }
          );
          this.$toasted.show(`Channel ${channelId} successfully added`, warningToastConfig)
          this.loading = false
        })
        .catch(err => {
          this.$toasted.show(err, warningToastConfig)
          this.loading = false
        })
      },
      async matchYoutubeUrl(){        
        let isValidURL = ''
        let isValidID = ''
        if(this.type == 'Channel'){
          const isValidVideoURL = validateVideoURL(this.youTubeUrl)
          isValidURL = validateChannelURL(this.youTubeUrl)
          isValidID = validateChannelID(this.youTubeUrl)

          if (isValidID) {
            await this.addChannel(this.bucket.bucketId, this.youTubeUrl)
            return false
          }else if(isValidURL){
            const channelID = formatChannelURLToID(this.youTubeUrl)
            await this.addChannel(this.bucket.bucketId, channelID)
            return false
          }else{
            if(isValidVideoURL){
              this.$toasted.show(ToastMessages.buckets.validVideoURL, warningToastConfig)
            }else{
              this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
            }
          }
        } else {
          isValidURL = validateVideoURL(this.youTubeUrl)
          isValidID = validateID(this.youTubeUrl)

          if (isValidID) {
            // await this.addChannel(this.bucket.bucketId, this.youTubeUrl)
            this.$toasted.show('Valid ID', warningToastConfig)
            return false
          }else if(isValidURL){
            // await this.addChannel(this.bucket.bucketId, formatChannelURLToID(this.youTubeUrl))
            this.$toasted.show('Valid URL', warningToastConfig)
            return false
          }else{
            this.$toasted.show(ToastMessages.discovery.invalid_url, warningToastConfig)
          }
        }
      },
      close(){
        this.$emit('close')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>