function validateVideoURL(data){
  const validateURL = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return data.match(validateURL)
}
function validateChannelURL(data){
  const validateURL = /(https?:\/\/)?(www\.)?youtube\.com\/(channel|c|user)\/[\w-]+/;
  return data.match(validateURL)
}
function validateID(data){
  const validateID = /[a-zA-Z0-9_-]{11}/;
  return data.match(validateID) && data.length == 11
}
function validateChannelID(data){
  let firstChars = data.slice(0, 2)
  return data.length == 24 && firstChars == 'UC'
}

export { validateVideoURL, validateID, validateChannelURL, validateChannelID }