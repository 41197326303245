<template>
  <button 
    class="flex justify-between items-center text-white py-1 rounded-3xl"
    :class="[
      { 'disabled:opacity-50': disabledButton || disabled },
      bgClass,
      borderClass,
      padXClass,
      heigthClass
    ]"
    @click="nextStep"
    :disabled="disabledButton || disabled"
    :type="type"
  >
    <span 
      class="whitespace-nowrap"
      :class="[ textClass ]"
    >{{ btnText }}</span>
    <Loader 
      v-if="disabled"
      :animation="true"
      classes="w-4 h-4 ml-3"
    />
  </button>
</template>

<script>
  import Loader from '@/components/Loader'
  
  export default {
    components: {
      Loader
    },
    props:{
      disabled: {
        type: Boolean,
        default: false
      },
      disabledButton: {
        type: Boolean,
        default: false
      },
      btnText: {
        type: String,
        default: 'Submit'
      },
      type: {
        type: String,
        default: 'text'
      },
      bgClass: {
        type: String,
        default: ''
      },
      textClass: {
        type: String,
        default: ''
      },
      borderClass: {
        type: String,
        default: ''
      },
      padXClass: {
        type: String,
        default: 'px-5'
      },
      heigthClass: {
        type: String,
        default: ''
      },
    },
    methods: {
      nextStep(){
        this.$emit('nextStep')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>